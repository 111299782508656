
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import PollService from "@/services/polls-service";

const interval: number = 25000;

export default Vue.extend({
  data() {
      return {
          poll: { name: "", id: 0 },
          questions: [],
          responses: {},
          currentPollIndex: 0,
          currentQuestionIndex: 0,
          showSurvey: false,
          previousAccountId: null,
          emojiList: ["😡", "😕", "😐", "😊", "😁"],
      };
  },

  async mounted() {
      //this.setupInterval();
      //window.addEventListener("storage", this.handleStorageChange);
      localStorage.setItem('surveyCancel', 'false');
  },

//   beforeDestroy() {
//       window.removeEventListener("storage", this.handleStorageChange);
//   },

  computed: {
      ...mapGetters("profile", ["account"]),
      ...mapGetters("polls", ["surveyCancel"]),

      surveyCancel() {
          return localStorage.getItem('surveyCancel') === 'true';
      },

      getAccountId() {
          return this.$store.state.profile.account ? this.$store.state.profile.account.id : null;
      },

      sliderColor() {
          const value = this.responses[this.currentQuestion?.id];
          switch (true) {
              case value <= 2:
                  return "#ff3d00";
              case value <= 4:
                  return "#ff9100";
              case value <= 6:
                  return "#ffea00";
              case value <= 8:
                  return "#64dd17";
              case value <= 10:
                  return "#2962ff";
              default:
                  return "#9e9e9e";
          }
      },

      currentQuestion() {
          return this.questions[this.currentQuestionIndex];
      },

      isLastQuestion() {
          return this.currentQuestionIndex === this.questions?.length - 1;
      },
  },

  methods: {
      ...mapActions("Polls", ["fetchPolls"]),
      ...mapActions("polls", ["SET_SURVEY_CANCEL"]),

      handleStorageChange(event) {
          if (event.key === "surveyCancel") {
              this.showSurvey = localStorage.getItem("surveyCancel") === "false";
          }
      },

      async setupInterval() {
          this.timer = setInterval(() => {
              this.loadPolls();
          }, interval);
      },

      async loadPolls() {
          try {
              const isCancel = localStorage.getItem("surveyCancel") === "true";
              if (isCancel) {
                  return;
              }
              const response = await this.fetchPolls();
              if (response.length > 0) {
                  this.polls = response;
                  this.poll = this.polls[this.currentPollIndex];
                  await this.loadQuestionsForCurrentPoll();
                  const isCancel = localStorage.getItem("surveyCancel") === "true";
                  if (!isCancel) {
                      this.showSurvey = true;
                  }
              } else {
                  console.warn("No se encontraron encuestas");
              }
          } catch (error) {
              console.error("Error al obtener encuestas:", error);
          }
      },

      async submitResponses() {
          const createPayload = () => ({
              poll_id: this.poll.id,
              responses: Object.keys(this.responses).map((question_id) => {
                  return {
                      question_id: parseInt(question_id),
                      response: typeof this.responses[question_id] === 'number' ? this.responses[question_id] : 0,
                  };
              }),
          });

          try {
              const payload = createPayload();
              await PollService.submitResponses(payload);
              this.resetSurveyData();
          } catch (error) {
              console.error("Error al enviar las respuestas:", error);
          }
      },

      resetSurveyData() {
          Object.keys(this.responses).forEach((question_id) => {
              this.responses[question_id] = 0;
          });
          this.responses = {};
          this.questions = [];
          this.showSurvey = false;
      },
      cancelSurvey() {
          localStorage.setItem('surveyCancel', 'true');
          window.addEventListener("storage", this.handleStorageChange);
          this.showSurvey = false;
      },

      async resetSurvey() {
          this.responses = {};
          this.currentPollIndex = 0;
          this.currentQuestionIndex = 0;
          this.poll = this.polls[this.currentPollIndex];
          this.loadQuestionsForCurrentPoll();
      },

      async loadQuestionsForCurrentPoll() {
          const currentPoll = this.polls[this.currentPollIndex];
          const questions = await PollService.getQuestionsByPollId(currentPoll.id);
          this.questions = questions;
          this.initializeResponses();
      },

      initializeResponses() {
          this.questions.forEach((question) => {
              this.$set(this.responses, question.id, question.type === "scale" ? null : "");
          });
      },

      getQuestionText(question) {
          const language = this.$store.state.internationalization.language.toUpperCase();
          return question[`question_${language}`] || question.question_EN;
      },

      nextQuestion() {
          if (this.currentQuestionIndex < this.questions.length - 1) {
              this.currentQuestionIndex++;
          } else if (this.currentPollIndex < this.polls.length - 1) {
              this.currentPollIndex++;
              this.currentQuestionIndex = 0;
              this.poll = this.polls[this.currentPollIndex];
              this.loadQuestionsForCurrentPoll();
          }
      },

      isSelectedEmoji(index) {
          const value = this.responses[this.currentQuestion?.id];
          switch (index) {
              case 1:
                  return value >= 1 && value <= 2;
              case 2:
                  return value >= 3 && value <= 4;
              case 3:
                  return value >= 5 && value <= 6;
              case 4:
                  return value >= 7 && value <= 8;
              case 5:
                  return value >= 9 && value <= 10;
              default:
                  return false;
          }
      },
  },

  watch: {
  getAccountId: {
      handler(newAccountId) {
          if (newAccountId !== this.previousAccountId && this.previousAccountId != null) {
            localStorage.setItem('surveyCancel', 'false');
              //this.showSurvey = false;
              this.surveyCancel = false; 
          }
          this.previousAccountId = newAccountId;
          this.responses = {};
          this.questions = [];
          if (newAccountId) {
          } else {
              console.warn("No hay cuenta disponible");
          }
      },
  },
},
});
